$anchor-text-color: #4a4a4a;
$default-color: #3d81f5 !default;
$sidebar-open-width: 19.44rem;
$sidebar-close-width: 3.33rem;
$regular-font-size: 1rem;
$sidebar-color: #fff;
$sidebar-bg: #323657;
$sidebar-sub-menu-bg: lighten($sidebar-bg, 4%);
$white-color: #ffffff !default;
$white-bg: #ffffff !default;

body,
.wrapper {
  min-height: 100vh;
  position: relative;
}

.wrapper {
  height: 100vh;
}

.main-panel > .content {
  padding: 0.55rem;
}

.main-panel,
.sidebar {
  max-height: 100%;
  height: 100%;
  transition: 0.7s;
}

a {
  cursor: pointer;
  text-decoration: none;
  color: $anchor-text-color;

  &:focus,
  &:hover {
    text-decoration: none;
    color: $anchor-text-color;
  }
}

.sidebar {
  max-height: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: $sidebar-open-width;
  display: block;
  z-index: 1;
  color: $sidebar-color;
  font-weight: 200;
  transition: 0.7s;
  background-color: $sidebar-bg;

  .sidebar-wrapper {
    background-color: $sidebar-bg;
    height: 100vh;
    padding-bottom: 3.33rem;
    overflow: auto; // Changed
    .desktop-sidebar-close {
      text-align: right;
    }
  }
  .brand {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 4.16rem;
    width: 100%;
    background-color: white;
    .logo {
      width: 5.55rem;
      margin-right: 0.55rem;
      object-fit: contain;
    }
    // .brand-name {
    //   color: #EA4C89;
    //   font-family: "SF UI Text";
    //   font-size: 16px;
    //   font-weight: 600;
    //   line-height: 19px;
    //   text-align: center;
    // }
  }
  .user-wrapper {
    .user {
      margin: 1.38rem auto 3.33rem auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      padding: 1.11rem;
      .photo {
        width: 8.33rem;
        height: 8.33rem;
        border-radius: 50%;
        padding: 0.19rem;
        background-color: $white-bg;
        transition: 1s ease;
        object-fit: contain;
      }
      .photo.hrect-photo {
        height: auto;
        border-radius: 10px;
        padding: 6px;
      }
      .userinfo {
        flex-grow: 1;
        transition: all 1s;
        .username {
          font-size: $regular-font-size;
          font-weight: 600;
          line-height: 1.05rem;
          .company-name {
            border-radius: 2.11rem;
            padding: 0.44rem 0.88rem;
            border-width: 1px;
            background-color: $default-color;
            margin-top: 1.11rem;
            text-align: center;
            margin-top: 1.38rem;
            width: 11.38rem; /* make this percent */
          }
        }
        .title {
          font-size: 0.66rem;
          font-weight: 300;
          line-height: 0.94rem;
        }
      }
      .collapse-arrow {
        font-size: 1.66rem;
        transition: all 0.3s ease;
      }
      .collapse-arrow.active {
        transform: rotate(180deg);
      }
    }
    .user-nav > li > {
      a,
      button {
        padding-left: 5rem;
        color: white;
        &:hover,
        &:active {
          color: black;
          background: white;
        }
        &:focus {
          color: white;
          background: transparent;
        }
      }
    }
    .user-nav > li > li > {
      a,
      button {
        padding-left: 5rem;
        color: white;
        &:hover,
        &:active {
          color: black;
          background: white;
        }
        &:focus {
          color: white;
          background: transparent;
        }
      }
    }
  }

  .nav.settings {
    // margin-top: 2.77rem;
  }
  > .sidebar-wrapper > .nav {
    > li {
      .caret.open {
        transform: rotate(180deg);
      }
      &.active > {
        a,
        button {
          color: black;
          background: #2a2c44;
          i {
            color: #3d81f5;
          }
          p {
            color: white;
            font-weight: 600;
            margin-top: 0.11rem;
          }
        }
      }
      a,
      button {
        display: flex;
        height: 1.11rem;
        align-items: center;
        padding: 1.27rem 2.38rem;
        font-size: $regular-font-size;
        color: $sidebar-color;
        &:hover {
          color: black;
          background: #2a2c44;
        }
      }
      button {
        height: auto;
        padding: 0.43rem 2.38rem;
        background: transparent;
        border: unset;
        width: 100%;
        outline: 0;
      }

      &.active .caret {
        //transform: rotate(180deg);
      }

      > div > ul.nav {
        background: $sidebar-sub-menu-bg;
        list-style-type: circle;

        li {
          &.active {
            a,
            button {
              background: #464974;
            }
          }
          > {
            a,
            button {
              display: flex;
              height: 1.11rem;
              align-items: center;
              padding: 1.27rem 2.38rem;
              font-size: $regular-font-size;
              color: $sidebar-color;
              &:hover {
                background: #464974;
              }
            }
            button {
              height: auto;
              padding: 0.43rem 2.38rem;
              background: transparent;
              border: unset;
              width: 100%;
              outline: 0;
            }
          }
        }
      }
    }

    i {
      font-size: 1.11rem;
      float: left;
      margin-right: 0.83rem;
      line-height: 1.66rem;
      text-align: center;
      color: #9799a8;
      height: 1.33rem;
      width: 1.33rem;
    }

    p {
      margin: 0;
      line-height: 1.66rem;
      font-size: $regular-font-size;
      white-space: nowrap;
      position: relative;
      color: #9799a8;
      opacity: 1;
      transition: opacity 0.7s;

      .caret {
        margin-left: 0.55rem;
        transition: all 0.2s ease;
      }
    }
  }
}

// COLLAPSE SIDEBAR CSS

.sidebar-close {
  .sidebar {
    width: $sidebar-close-width;
    ul {
      max-width: 1px;
    }
    .sidebar-wrapper {
      margin-top: 1.11rem;
      overflow: hidden; // Changed
    }
    .desktop-sidebar-close {
      margin-bottom: 1.11rem;
      text-align: center;
    }
    .menu-icon {
      font-size: 2.5rem;
      display: block;
      color: #3d81f5;
      margin: 0 auto;
      text-align: center;
      position: relative;
      &:hover {
        color: white;
      }
    }
    .nav {
      p {
        opacity: 0;
      }
      li {
        a,
        button {
          padding-top: 1.11rem;
          padding-bottom: 1.11rem;
          padding-left: 0.77rem;
        }
      }
      li > div > ul.nav > li > {
        a,
        button {
          padding-left: 0.77rem;
        }
      }
    }
    .user-wrapper {
      margin-bottom: 1.66rem;
      .user {
        margin: 0.55rem auto;
        padding: 0px;
        .userinfo {
          transform: scale(0, 0);
          height: 0;
        }
        .photo-container .photo {
          margin-top: 0.55rem;
          width: 2.22rem;
          height: 2.22rem;
          border-radius: 50%;
          padding: 1px;
          transition: 0.7s ease;
        }
      }
    }
  }
  .main-panel,
  .wrapper-full-page {
    width: calc(100% - #{$sidebar-close-width});
    left: 0;
    overflow: scroll;
    // transition: 2s ease;
  }
}

.main-panel {
  position: relative;
  float: right;
  width: calc(100% - #{$sidebar-open-width});
  min-height: 100%;
  overflow: auto;
  background-color: #fafcfe;
  box-shadow: 0 1.66rem 7.22rem 0 rgba(90, 105, 116, 0.1);
  padding-top: 0.83rem;
}

@media (max-width: 480px) {
  .sidebar,
  .bootstrap-navbar {
    position: fixed;
    display: block;
    top: 0;
    height: 100%;
    width: $sidebar-open-width;
    left: 0;
    // left: auto;
    z-index: 1032;
    visibility: visible;
    overflow-y: visible;
    padding: 0;
    transform: translateX(-$sidebar-open-width);
  }
  .main-panel,
  .wrapper-full-page {
    // transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
    left: 0;
    width: 100%;
    transform: translateX(0);
  }
  .nav-open {
    .main-panel,
    .wrapper-full-page {
      transform: translateX($sidebar-open-width);
      left: 0;
      overflow: hidden;
    }
    .sidebar {
      transform: translateX(0);
    }
    .close-layer {
      opacity: 1;
      // visibility: visible;
    }
  }

  .mobile-sidebar-close {
    font-size: 3rem;
    color: #3d81f5;
    margin: 0 auto;
    text-align: center;
    position: relative;
    right: 0;
    i {
      &:hover {
        // background: #3d81f5;
        color: white;
      }
    }
  }
  .desktop-sidebar-close {
    display: none;
  }
}

.left-circle-icon {
  font-size: 2.5rem;
  font-weight: bold;
  margin-top: 0.5rem;
  margin-right: 1.5rem;
  color: #3d81f5;
  &:hover {
    color: white;
  }
}

.close-layer {
  height: 100%;
  width: 100%;
  position: absolute;
  opacity: 0;
  top: 0;
  left: auto;
  content: "";
  z-index: 1000;
  overflow-x: hidden;
  visibility: hidden;
  background-color: rgba(0, 0, 0, 0.35);
}

.caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px dashed;
  border-top: 4px solid\9;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}
