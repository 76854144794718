@import '../bootstrap-4.3.1/scss/bootstrap.scss';

@import 'base/layout';
@import 'base/sidebar';
@import 'base/card';
@import 'base/heading';
@import 'base/slider';
@import 'base/form';
@import 'base/common';
@import 'base/buttons';
@import 'base/react-dates';
@import 'base/header';
@import 'base/table';
@import 'base/print';
@import 'base/file-upload';
@import 'base/custom-multiselect';
@import './override.scss';
@import '../styles/loading_spinner.scss';
html, body {
  font-size: 18px;
}

@media only screen and (max-width : 1919px) {
  html, body {
    font-size: 14px;
  }
}

@media only screen and (max-width : 1400px) {
  html, body {
    font-size: 12px;
  }
}



