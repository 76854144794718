.card {
	.form {
		padding: 0.39rem 0.66rem;
		.radio-container {
			align-items: center;
    	height: calc(100% - 2rem);
    	label.form-check-label {
    		white-space: nowrap;
    	}
		}
		.form-group {
		  .error {
		    text-transform: none;
		    color: #FB404B;
		    margin-top: 5px;
		    font-size : .8rem;
		  }
		  .form-check-inline.custom-align {
		  	align-items: center;
		  	input {
		  		margin-top: 1px;
		  	}
		  }
		}
		hr.divider {
			margin-right: -2.5rem;
			margin-left: -2.5rem;
			margin-top: 3rem;
  		margin-bottom: 3rem;
  		&.small {
  			margin-top: 2rem;
  			margin-bottom: 2rem;
  		}
		}
		label {
			font-size: 1rem;
    	font-weight: 700;
    	margin-bottom : 1rem;
		}
		label.form-check-label {
			font-weight: 500;
			margin-bottom : 0;
		}
		.reactselect__control {
			min-height: 29px;
		}
		.reactselect__indicators {
		  background: #2d3152;
		  border-top-right-radius: .16rem;
		  border-bottom-right-radius: .16rem;
		}
		.reactselect__indicator-separator {
		  display: none;
		}
		.reactselect__indicator.reactselect__dropdown-indicator {
		  color: white;
		  padding: 0 5px;
		}

		.reactselect__control.reactselect__control--is-focused, .reactselect__control.reactselect__control--is-focused:hover {
		  border: 1px solid gray;
		  box-shadow: 0 0 0 1px transparent;
		}
	}
}

.form-group {
  .error {
    text-transform: none;
    color: #FB404B;
    margin-top: 5px;
    font-size : .8rem;
  }
  ul.error {
  	padding: 0;
  	list-style: none;
  }
  .mini-input {
  	text-align: center;
  	width: 70px;
    border-color: #167bff;
    border: unset;
    border: 1px solid #167bff;
    border-radius: 3px;
    padding: 3px;
    margin: 2px;
    font-size: 12px;
    margin-left: 4px;
  }
}


