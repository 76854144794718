.DateInput {
  width: calc(100% - (.88rem + 32px));
}
.DateInput_input {
	padding: .27rem .61rem .38rem;
}

.SingleDatePickerInput_calendarIcon {
  margin-right: 0;
  background: #2d3152;
  border-top-right-radius: .16rem;
  border-bottom-right-radius: .16rem;
  height: auto;
  border: 1px solid #2d3152;
  color : white;
  padding: 0.38rem .44rem;
}

td.rdtMonth, td.rdtYear {
  border-radius: 5px;
}

.rdtOpen .rdtPicker {
  padding: 12px;
  box-shadow: 0 0 18px -5px #808080a1;
  // width: 225px;
  width: 100%;
}

.rdtMonths table:first-child {
  margin-bottom: 10px;
}
.rdtPicker th {
  border: unset;
}
