
.card.pill {
  padding : 1rem 0;
  .card-body {
    padding: 0.83rem 0.55rem;
    .icon-with-bg {
			height: 2.77rem;
			width: 2.77rem;
			background: #cee0ff;
			border-radius: 50%;
			display: inline-flex;
			align-items: center;
			justify-content: center;
			margin-bottom: 0.55rem;
			font-size: 2rem;
		}
    p {
      margin-bottom: 0;
      font-size: 1rem;
      &.bold {
        font-size: 1.60rem;
        font-weight: bold;
      }
    }
  }  
}

.card.performance, .card.demographic {
	.card-title {
		font-size: 1rem;
		padding: 1.77rem 0 0px;
		font-weight: 600;
		text-align: center;
	}
	.card-body {
		padding: 0.55rem 1.66rem 1.11rem 1.66rem;
	}
}

.performance {
	.tab-selector {
    border-color: transparent;
    background: #f5f5f9;
	color: #505050;
    // border: 1px solid #c4cad1;
    &:not(:disabled).active {
      border-color: #005cbf;
      background: white;
      color: #0062cc;
    }
  }
}
.demographic {
	.v-divider {
		border-right: 1px solid #eee;
	}
	.timeline {
	  // padding: 3.33rem 0 0 1.38rem;
	  // height: calc(100% - 170px);
	  height: calc(100% - 130px);
    padding: 3.33rem 0 0 1.66rem;
	}
	.rc-slider-handle {
		border-width: 0.34rem;
		height: 1.11rem;
		width: 1.11rem;
		margin-left: -0.3rem;
		margin-bottom: -0.22rem;
	}
	.rc-slider-track, .rc-slider-rail {
		background-color: #e9e9e9;
    width: 5px;
	}
	.rc-slider-dot {
		border-color: #b7b7b7;
		display: inline;
		border-width: 0;
		background-color: #e9e9e9;
		height: 1.11rem;
		width: 1.11rem;
		margin-left: -0.5rem;
	}
	.tab-selector {
		border-color: transparent;
		background: #f5f5f9;
		color: #505050;
		// border: 1px solid #c4cad1;
		&:not(:disabled).active {
		  border-color: #005cbf;
		  background: white;
		  color: #0062cc;
		}
	}
}




