.file-upload {
  label {
    height: 8.33rem;
    width: 8.33rem;
    cursor: pointer;
    img {
      width: 100%;
      height: 100%;
      // border-radius: 50%;
      object-fit: contain;
    }
  }
  input {
    display: none;
  }
}