.cursor-pointer {
  cursor : pointer;
}
.table-bordered thead th {
  white-space: nowrap;
  text-align: center;
}
.nowrap { 
  white-space : nowrap;
}

.condenced th {
  white-space: nowrap;
  font-size: 13px;
  padding: 20px 5px;
  text-align: center;
}
.table td.vhalign, .table th.vhalign {
  vertical-align : middle;
  text-align : center;
  max-width : 250px;
}
td .btn-simple.btn-icon {
  padding-top : 0;
  padding-bottom : 0;
}

.react-bootstrap-table table {
  table-layout: auto;
}

.react-bootstrap-table-page-btns-ul {
  justify-content : flex-end;
}

.react-bootstrap-table-pagination-list-hidden {
    display: none;
}
.react-bootstrap-table th.sortable {
  outline : none;
}