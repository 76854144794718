

    button:focus {
      outline:  0px auto -webkit-focus-ring-color !important
    }
  
 

.MuiFormControl-marginNormal {
  margin-top: 0px !important;
 margin-bottom: 0px !important;
}

.MuiOutlinedInput-input {
  padding: 10.5px 14px !important;
}


.MuiIconButton-root{
    padding: 1px !important;
}
.m-t-20 {
  margin-top: 20px;
}
.view-invoice {
  max-width: 85px;
}


